import React, { useRef } from 'react';
import './contact.css';
import {MdOutlineMail} from 'react-icons/md';
import {RiMessengerLine, RiWhatsappLine} from 'react-icons/ri';
import emailjs from 'emailjs-com';

const Contact = () => {

  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lu214dd', 'template_9rlyam7', form.current, 'czNpgcWlkuSDoON-t')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset()  
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

       <div className="container contact__container">
         <div className="contact__options">
           <article className="contact__option">
             <MdOutlineMail className="contact__option-icon" />
             <h4>Email</h4>
             <h5>wildmulti@gmail.com</h5>
             <a target='_blank' rel="noopener noreferrer" href="mailto:wildmulti@gmail.com">Send a message</a>
           </article>
           
           <article className="contact__option">
             <RiMessengerLine className="contact__option-icon" />
             <h4>Messenger</h4>
             <h5>penasNipute</h5>
             <a target='_blank' rel="noopener noreferrer" href="https://m.me/penasnipute">Send a message</a>
           </article>
           
           <article className="contact__option">
             <RiWhatsappLine className="contact__option-icon" />
             <h4>Whatsapp</h4>
             <h5>penasNipute</h5>
             <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=258842340980">Send a message</a>
           </article>
         </div>
        
         {/* END OF CONTACT OPTIONS */}
          <form 
          ref={form} onSubmit={sendEmail}
          >
            <input type="text" name="name" placeholder='Your Full Name' required />
            <input type="email" name="email" placeholder='Your Email' required />
            <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
            <button type="submit" className='btn btn-primary'>Send message</button>
          </form>
       </div>
    </section>
  )
}

export default Contact